import React, { useState, useEffect } from 'react';

import Container from '@material-ui/core/Container';
import { CssBaseline, Avatar, Backdrop, CircularProgress, Snackbar, MenuItem, Menu, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import SessionUserList from '../../components/SessionUserList';
import { SessionPhoto, Tourist, Session } from "./session-photo.dto";
import { getDataLocalByKey, removeAllDataLocal, ACCESS_TOKEN, FULL_NAME } from '../../utils/sessionstorage';
import { navigate } from 'gatsby';
import { api } from '../../services/api';
import { isMobile } from 'react-device-detect';
import MobileNotSupport from '../../components/MobileNotSupport';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import appbarLogo from '../../images/travelpix-logo.svg';
import avatarLogo from '../../images/logo.svg';

import { convertTime24hTo12h, convertDate } from '../../utils/converter';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#1d2332'
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    marginLeft: 16
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  userName: {
    marginRight: 16
  },
  menu: {
    width: 140
  },
  uploadMore: {
    backgroundColor: '#FBCA00',
    textTransform: 'none',
    fontWeight: 800,
    marginRight: 40
  }
}));

const IndexPage = () => {
  const [openLoading, setOpenLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [alertMessage, setAlertMessage] = useState({
    type: 'success',
    message: '',
    show: false
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [sessionValue, setSessionValue] = React.useState('');
  const [sessionsOnly, setSessionsOnly] = useState([]);
  const [requestSessionId, setRequestSessionId] = useState('');

  const classes = useStyles();

  const loadData = () => {
    setOpenLoading(true);

    api({
      method: 'get',
      url: `/session-photo/sessions`,
      data: null,
      tokenExpiredCallback: () => navigate('/sign-in'),
      tokenHeaders: {
        Authorization: 'Bearer ' + getDataLocalByKey(ACCESS_TOKEN),
      }
    }).then(response => {
      setOpenLoading(false);

      if ('success' in response.data && response.data.success === true) {
        setSessions([]);

        var sessionResponse: SessionPhoto[] = [];
        var sessionsOnly: SessionPhoto[] = [];

        response.data.data.forEach(reservation => {
          sessionsOnly.push(
            new SessionPhoto(
              null,
              new Session(
                reservation._id,
                reservation.location.thumbnailUrl,
                reservation.location.name,
                reservation.reservationDate,
                reservation.startTime,
                reservation.endTime,
                reservation.status),
              0
            )
          );

          reservation.tourists.forEach(tourist => {
            sessionResponse.push(
              new SessionPhoto(
                new Tourist(
                  tourist._id,
                  tourist.avatarUrl,
                  tourist.firstName,
                  tourist.lastName,
                  tourist.email),
                new Session(
                  reservation._id,
                  reservation.location.thumbnailUrl,
                  reservation.location.name,
                  reservation.reservationDate,
                  reservation.startTime,
                  reservation.endTime,
                  reservation.status),
                reservation.sessionPhotos.filter(photo => (photo.reservation === reservation._id && photo.tourist === tourist._id)).length
              )
            )
          })
        });
        setSessions(sessionResponse);
        setSessionsOnly(sessionsOnly);
      } else {
        setAlertMessage({
          type: 'error',
          message: response.data.message,
          show: true
        });
      }
    }).catch(error => {
      console.log(error);
      setOpenLoading(false);

      setAlertMessage({
        type: 'error',
        message: 'An error occured. Please refresh this page.',
        show: true
      });
    });
  }

  useEffect(() => {
    if (!getDataLocalByKey(ACCESS_TOKEN)) {
      navigate('/sign-in/');
    }
    loadData();
  }, []);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseSnackbar = () => {
    setAlertMessage({ ...alertMessage, show: false });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    removeAllDataLocal();
    navigate('/sign-in');
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleRequestSession = () => {
    setOpenDialog(false);
    setOpenLoading(true);

    api({
      method: 'post',
      url: `/session-photo/request-upload-more`,
      data: {
        sessionId: requestSessionId
      },
      tokenExpiredCallback: () => navigate('/sign-in'),
      tokenHeaders: {
        Authorization: 'Bearer ' + getDataLocalByKey(ACCESS_TOKEN),
      }
    }).then(response => {
      setOpenLoading(false);

      if ('success' in response.data && response.data.success === true) {
        setRequestSessionId('');
        setAlertMessage({
          type: 'info',
          message: 'Your request has been sent to TravelPix team. We will review and contact you through your registered email!',
          show: true
        });
      } else {
        setAlertMessage({
          type: 'error',
          message: response.data.message,
          show: true
        });
      }
    }).catch(error => {
      console.log(error);
      setOpenLoading(false);

      setAlertMessage({
        type: 'error',
        message: 'An error occured. Please try again.',
        show: true
      });
    });
  };

  const handleSessionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSessionValue((event.target as HTMLInputElement).value);
    setRequestSessionId((event.target as HTMLInputElement).value);
  };

  if (isMobile) {
    return (
      <MobileNotSupport />
    )
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img src={appbarLogo} alt="logo" />
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Session photo upload
        </Typography>
          <Fab
            variant="extended"
            size="large"
            aria-label="request"
            className={classes.uploadMore}
            onClick={() => {
              handleOpenDialog()
            }}
            disabled={!sessions || sessions.length === 0}
          >
            <UnfoldMoreIcon />
              Request to upload more
          </Fab>
          <Typography
            aria-controls="simple-menu"
            aria-haspopup="true" onMouseEnter={handleClick}
            onClick={handleClick}
            className={classes.userName}>
            {`${getDataLocalByKey(FULL_NAME)}`}
          </Typography>
          <Avatar
            aria-controls="simple-menu"
            aria-haspopup="true"
            alt="Avatar"
            src={avatarLogo} />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleSignOut} className={classes.menu}>Sign out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="lg" className={classes.container}>

          <Dialog open={openDialog} onClose={handleCloseDialog} disableBackdropClick aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Choose session to request</DialogTitle>
            <DialogContent>
              <FormControl component="fieldset">
                <RadioGroup aria-label="session" name="sessions1" value={sessionValue} onChange={handleSessionChange}>
                  {
                    sessionsOnly.map(item => {
                      return <FormControlLabel value={item.session.id} control={<Radio />} label={`${item.session.locationName} ${convertDate(item.session.reservationDate)} @ ${convertTime24hTo12h(item.session.startTime)} - ${convertTime24hTo12h(item.session.endTime)}`} />
                    })
                  }
                </RadioGroup>
              </FormControl>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleRequestSession} color="primary">
                Request for this session
            </Button>
              <Button onClick={handleCloseDialog} color="secondary">
                Close
            </Button>
            </DialogActions>
          </Dialog>

          <Backdrop className={classes.backdrop} open={openLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <Snackbar open={alertMessage.show} autoHideDuration={2000} onClose={handleCloseSnackbar}>
            <Alert severity={alertMessage.type}>
              {alertMessage.message}
            </Alert>
          </Snackbar>

          {
            sessions && sessions.length !== 0 ?
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <SessionUserList sessions={sessions} onRefreshData={loadData} />
                  </Paper>
                </Grid>
              </Grid> :
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography component="h6" variant="h6" color="inherit">You haven't had any session yet.</Typography>
                  </Paper>
                </Grid>
              </Grid>
          }
        </Container>
      </main>
    </div>
  );
}

export default IndexPage;
