
export class Tourist {
    id: string;
    avatarUrl: string;
    firstName: string;
    lastName: string;
    email: string;
    canUpload: boolean;

    constructor(id: string, avatarUrl: string, firstName: string, lastName: string, email: string) {
        this.id = id;
        this.avatarUrl = avatarUrl;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
    }
}

export class Session {
    id: string;
    locationPhotoUrl: string;
    locationName: string;
    reservationDate: Date;
    startTime: string;
    endTime: string;
    status: string;

    constructor(id: string, locationPhotoUrl: string, locationName: string, reservationDate: Date, startTime: string, endTime: string, status: string) {
        this.id = id;
        this.locationPhotoUrl = locationPhotoUrl;
        this.locationName = locationName;
        this.reservationDate = reservationDate;
        this.startTime = startTime;
        this.endTime = endTime;
        this.status = status;
    }
}

export class SessionPhoto {
    tourist: Tourist;
    session: Session;
    photos: number;

    constructor(tourist: Tourist, session: Session, photos: number) {
        this.tourist = tourist;
        this.session = session;
        this.photos = photos;
    }
}