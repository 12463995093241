import React, { useState, FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Fab, Backdrop, CircularProgress, Snackbar, ListItemSecondaryAction, Grid } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { storage } from '../../firebase/firebase';
import { api, api1 } from '../../services/api';
import MuiAlert from '@material-ui/lab/Alert';
import { getDataLocalByKey, ACCESS_TOKEN } from '../../utils/sessionstorage';
import { navigate } from 'gatsby';
import { convertTime24hTo12h, convertDate } from '../../utils/converter';

import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
const { Dragger } = Upload;

export interface Tourist {
  id: string,
  avatarUrl: string,
  firstName: string,
  lastName: string,
  email: string
}

export interface Session {
  locationPhotoUrl: string,
  locationName: string,
  id: string,
  reservationDate: Date,
  startTime: string,
  endTime: string,
  status: string;
}

export interface SessionPhoto {
  tourist: Tourist,
  session: Session
  photos: number;
}

export interface Props {
  sessions: SessionPhoto[];
  onRefreshData: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline'
  },
  avatarTourist: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  avatarLocation: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  listItemText: {
    marginLeft: '24px',
    width: '25%'
  },
  sessionInline: {
    display: 'inline',
  },
  sessionItemText: {
    marginLeft: '24px',
    fontWeight: 800
  },
  fabMargin: {
    margin: theme.spacing(1),
    backgroundColor: '#FBCA00',
    textTransform: 'none',
    fontWeight: 800
  },
  fabExtendedIcon: {
    marginRight: theme.spacing(1),
  },
  dropzone: {
    background: 'white',
    borderRadius: '5px',
    border: '2px dashed rgb(0, 135, 247)',
    borderImage: 'none',
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  backdrop: {
    zIndex: 2000,
    color: '#fff',
  },
  actionText: {
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'center'
  },
  rootGrid: {
    flexGrow: 1,
    marginBottom: 24
  },
}));

const SectionUserList: FunctionComponent<Props> = props => {
  const classes = useStyles();

  const { sessions, onRefreshData } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [position, setPosition] = useState(0);
  const [alertMessage, setAlertMessage] = useState({
    type: 'success',
    message: '',
    show: false
  });
  var photoIds = [];

  const handleOpenDialog = (index: number) => {
    setOpenDialog(true);
    setPosition(index);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    photoIds = [];
    onRefreshData();
  };

  const handleUploadedAllPhotos = () => {
    setOpenLoading(true);
  
    api({
      method: 'post',
      url: `/session-photo/upload-completed`,
      data: {
        session: sessions[position].session.id,
        tourist: sessions[position].tourist.id,
      },
      tokenExpiredCallback: () => navigate('/sign-in'),
      tokenHeaders: {
        Authorization: 'Bearer ' + getDataLocalByKey(ACCESS_TOKEN),
      }
    }).then(response => {
      setOpenLoading(false);

      if ('success' in response.data && response.data.success === true) {
        photoIds = [];
        onRefreshData();
        setOpenDialog(false);
      } else {
        setAlertMessage({
          type: 'error',
          message: response.data.message,
          show: true
        });
      }
    }).catch(error => {
      setOpenLoading(false);
      console.log(error);

      setAlertMessage({
        type: 'error',
        message: 'An error occured. Please try again.',
        show: true
      });
    });
  }

  const handleCloseSnackbar = () => {
    setAlertMessage({ ...alertMessage, show: false });
  }

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const uploadProps = {
    name: 'file',
    multiple: true,
    accept: 'image/*',
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: true
    }
  };

  const beforeUpload = (file) => {
    const isImage = file.type.indexOf('image/') === 0;
    if (!isImage) {
      setAlertMessage({
        type: 'error',
        message: 'You can only upload image file',
        show: true
      });
    }
    return isImage;
  };

  const onRemove = (file) => {
    let photoId = photoIds.filter(photoId => photoId === file.xhr.metadata.id);
    let photoIndex = photoIds.indexOf(photoId);

    api({
      method: 'delete',
      url: `/session-photo/${photoId}`,
      data: null,
      tokenExpiredCallback: () => navigate('/sign-in'),
      tokenHeaders: {
        Authorization: 'Bearer ' + getDataLocalByKey(ACCESS_TOKEN),
      }
    }).then(response => {
      setOpenLoading(false);

      if ('success' in response.data && response.data.success === true) {

        photoIds = photoIds.splice(photoIndex, 1);
      } else {
        setAlertMessage({
          type: 'error',
          message: response.data.message,
          show: true
        });
      }
    }).catch(error => {
      setOpenLoading(false);
      console.log(error);

      setAlertMessage({
        type: 'error',
        message: 'An error occured. Please try again.',
        show: true
      });
    });
    return true;
  }

  const customUpload = async ({ onError, onSuccess, file }) => {
    let parentRef = `/origin_session_photos/${sessions[position].tourist.id}/${sessions[position].session.id}`;

    let metadata = {
      contentType: 'image/jpeg'
    }

    try {
      let fileExtenstion = file.name.split('.');
      let fileName = `${file.name.replace(`.${fileExtenstion[fileExtenstion.length - 1]}`, '')}_${Date.now()}.${fileExtenstion[fileExtenstion.length - 1]}`;
      let image = await storage.ref(`${parentRef}/${fileName.replace(/[^A-Za-z0-9._]/g, '')}`).put(file, metadata);
      let downloadUrl = await storage.ref(parentRef).child(fileName.replace(/[^A-Za-z0-9._]/g, '')).getDownloadURL();
      let fullPath = await storage.ref(parentRef).child(fileName.replace(/[^A-Za-z0-9._]/g, '')).fullPath;

      api1({
        method: 'post',
        url: 'https://us-central1-travelpix-29c02.cloudfunctions.net/watermarkPhoto',
        data: {
          filePath: fullPath,
          photoUrl: downloadUrl,
          reservation: sessions[position].session.id,
          tourist: sessions[position].tourist.id,
          name: fileName.replace(/[^A-Za-z0-9._]/g, '')
        },
        tokenExpiredCallback: () => navigate('/sign-in'),
        tokenHeaders: {
          Authorization: 'Bearer ' + getDataLocalByKey(ACCESS_TOKEN),
        }
      }).then(response => {
        setOpenLoading(false);

        if ('success' in response.data && response.data.success === true) {
          image.metadata['id'] = response.data.data;
          photoIds.push(response.data.data);
          onSuccess(null, image);
        } else {
          setAlertMessage({
            type: 'error',
            message: response.data.message,
            show: true
          });
        }
      })
    } catch (error) {
      console.log(error);
      onError(error);
      setOpenLoading(false);

      setAlertMessage({
        type: 'error',
        message: 'An error occured. Please try again.',
        show: true
      });
    }
  };

  return (
    <div>
      <Dialog open={openDialog} onClose={handleCloseDialog} disableBackdropClick aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Upload session photos</DialogTitle>
        <DialogContent>
            <Grid container className={classes.rootGrid} spacing={2}>
              <Grid item xs={2}>
                <Avatar alt="Tourist avatar" src={sessions[position].tourist.avatarUrl} className={classes.avatarTourist} key={sessions[position].tourist.avatarUrl} />
              </Grid>
              <Grid item xs={6}>
                <DialogContentText>
                  You can drag/click to browse one or multiple photos at the same time.
                </DialogContentText>
              </Grid>
            </Grid>

          <Dragger
            {...uploadProps}
            beforeUpload={beforeUpload}
            customRequest={customUpload}
            onRemove={onRemove}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag photos to this area to upload</p>
          </Dragger>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadedAllPhotos} color="primary">
            I've uploaded all photos!
          </Button>
          <Button onClick={handleCloseDialog} color="secondary">
            Close
          </Button>
        </DialogActions>

        <Backdrop className={classes.backdrop} open={openLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>

      <Snackbar open={alertMessage.show} autoHideDuration={2000} onClose={handleCloseSnackbar}>
        <Alert severity={alertMessage.type}>
          {alertMessage.message}
        </Alert>
      </Snackbar>

      <List className={classes.root}>
        {
          sessions.map((item, index) => {
            return (
              <div key={index}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt="Tourist avatar" src={item.tourist.avatarUrl} className={classes.avatarTourist} key={item.tourist.avatarUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${item.tourist.firstName} ${item.tourist.lastName}`}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body1"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {item.tourist.email}
                        </Typography>
                      </React.Fragment>
                    }
                    className={classes.listItemText}
                  />
                  <ListItemAvatar>
                    <Avatar alt="Location avatar" variant="rounded" src={item.session.locationPhotoUrl} className={classes.avatarLocation} key={item.session.locationPhotoUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.session.locationName}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body1"
                          className={classes.sessionInline}
                          color="textPrimary"
                        >
                          {`${convertDate(item.session.reservationDate)} @ ${convertTime24hTo12h(item.session.startTime)} - ${convertTime24hTo12h(item.session.endTime)}`}
                        </Typography>
                      </React.Fragment>
                    }
                    className={classes.sessionItemText}
                  />
                  <ListItemSecondaryAction>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      className={classes.fabMargin}
                      disabled={item.session.status === 'PhotoUploaded'}
                      onClick={() => {
                        handleOpenDialog(index)
                      }}
                    >
                      <BackupIcon className={classes.fabExtendedIcon} />
                  Upload photo
                </Fab>
                    <Typography className={classes.actionText}>{`${item.photos} photo${item.photos > 0 ? 's' : ''} uploaded`}</Typography>
                  </ListItemSecondaryAction>
                </ListItem>
                {
                  index < sessions.length - 1 ? <Divider variant="fullWidth" component="li" /> : null
                }
              </div>
            )
          })
        }
      </List>
    </div>
  );
}

export default SectionUserList;
