import * as moment from 'moment';

const convertTime24hTo12h = (time24h: string) => {
    return moment(time24h, 'h:mm').format('hh:mma');
}

const convertDate = (date: Date) => {
    return moment(date).format('ddd Do MMM');
}

export { convertTime24hTo12h, convertDate };